/* You can add global styles to this file, and also import other style files */
// Import your custom theme
@import './theme.scss';

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
	background-color: #fafafa ;
	font-family: Roboto, "Helvetica Neue", sans-serif ;
	font-size: 12px;
}
	


.container {
    height: 100%; 
    width: 100%;
}


.pr, .pr-bare {
	color: $themePR;
}

.pr::before {
	background-image: url('assets/markers/marker-icon-pr.svg');
	background-size: 10px 17px;
    display: inline-block;
    height: 17px;
	width: 10px;
    content:"";
	margin-right: 5px;
}

.ph, .ph-bare {
	color: $themePH;
    // text-shadow: 1px 1px 0 #ddd;
}

.ph::before {
	background-image: url('assets/markers/marker-icon-ph.svg');
	background-size: 10px 17px;
    display: inline-block;
    height: 17px;
	width: 10px;
    content:"";
	margin-right: 5px;
}

.co, .co-bare {
	color: $themeCO;
    // text-shadow: 1px 1px 0 #ddd;
}

.co::before {
	background-image: url('assets/markers/marker-icon-co.svg');
	background-size: 10px 17px;
    display: inline-block;
    height: 17px;
	width: 10px;
    content:"";
	margin-right: 5px;
}

.ho, .ho-bare {
	color: $themeHO;
    // text-shadow: 1px 1px 0 #ddd;
}

.ho::before {
	background-image: url('assets/markers/marker-icon-ho.svg');
	background-size: 10px 17px;
    display: inline-block;
    height: 17px;
	width: 10px;
    content:"";
	margin-right: 5px;
}

a {
	color: $link;
}

a:hover {
	color: $linkHover;
}

.im, .im-bare {
	color: $themeIM;
	// text-shadow: 1px 1px 0 #ddd;
}

.im::before {
	background-image: url('assets/markers/marker-icon-im.svg');
	background-size: 10px 17px;
    display: inline-block;
    height: 17px;
	width: 10px;
    content:"";
	margin-right: 5px;
}

.tooltip-text {
	// background: $brandmain2 !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 0.9em !important;
	// font-family: Roboto, "Helvetica Neue", sans-serif  !important;
  }


// cluster styling
.cluster-container, .cluster-text {
	height: 48px;
	width: 44px;
	text-align: center;
}
.cluster-text {
	padding-top: 24px;
	font-size: 1.3em;
	font-weight: bold;
	color: rgba(255,255,255,0.9);
	text-shadow: -1px 0 3px rgba(0,0,0,0.5), 0 1px 3px rgba(0,0,0,0.5), 1px 0 3px rgba(0,0,0,0.5), 0 -1px 3px rgba(0,0,0,0.5);
	background-image: url('../src/assets/markers/marker-cluster.svg');
	background-position: center;
	background-repeat: no-repeat;
}

.mat-checkbox-label {
	// font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.no-wrap .mat-chip-list-wrapper {
    flex-wrap: nowrap !important;
}

ul li.check {
	list-style-image: url('assets/check.png')
}

ul li.rainbow {
	list-style-image: url('assets/check-rainbow.png')
}

.override-position .filter {
    position: relative !important;
}

.radius-snackbar, .radius-snackbar-lg, .radius-snackbar .mat-simple-snackbar-action {
	background-color: $brandsecondary3 !important;
	color: white !important;
}

.radius-snackbar-lg {
	margin-top: 17vh !important;
	margin-right: 16vw !important;
}

.app-invalid.ng-touched.ng-invalid{
    border:2px solid red;
}

.mat-tab-header {
    margin-bottom: 16px;
}

mat-dialog-container {
    height: 100% !important;
}

mat-expansion-panel {
	margin-bottom: 3px !important;
}

.text-emphasis {
    font-weight: 900; 
    text-decoration: underline;
}

.resp-tray {
    z-index: 99999 !important; 
    width: 100% !important;
}

.phone-icon {
    width: 55px !important;
}

.notes-text {
	word-wrap: break-word;
}

.mat-checkbox-label {
	padding: 3px 5px 3px 0 !important;
	line-height: 16px !important;
}