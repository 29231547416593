
// branding colours
$brandmain1: #f36e44;
$brandmain2: #ac4ba7;
$brandmain3: #787006;

$brandsecondary1: #ef4624;
$brandsecondary2: #462565;
$brandsecondary3: #007362;

$brandsupporting1: #fab393;
$brandsupporting2: #f7cdcf;
$brandsupporting3: #a28fb1;
$brandsupporting4: #89b2bc;
$brandsupporting5: #65aaa1;
$brandsupporting6: #58595b;

$brandsupporting7: #C3A9DA;
$brandsupporting8: #FF8E7F;
$brandsupporting9: #F26E45;



$brandcustom1: #c29b6a;
$brandcustom2: #667761;
$brandcustom3: #2880ca;
$brandcustom4: #d07545;
$brandcustom5: #799a4a;

$branding-blue:#005697;

// $highlight: rgba($brandsupporting2, 0.4);
$highlight: #fdc36f;
$link: #3b71b7;
$linkHover: #408a4d;

// theme colours
$themePR: $brandmain2;
$themeHO: $brandcustom4;
$themePH: $brandmain3;
$themeCO: $brandcustom3;
$themeIM: $brandcustom5;

$lpa2-blue: (
    50 : #e1eaf3,
    100 : #b3cbe2,
    200 : #81a8cf,
    300 : #4f85bc,
    400 : #296bad,
    500 : #03519f,
    600 : #034a97,
    700 : #02408d,
    800 : #023783,
    900 : #012772,
    A100 : #a1b8ff,
    A200 : #6e92ff,
    A400 : #3b6cff,
    A700 : #2159ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$lpa2-green: (
    50 : #ecede2,
    100 : #d0d3b6,
    200 : #b1b686,
    300 : #919856,
    400 : #7a8231,
    500 : #626c0d,
    600 : #5a640b,
    700 : #505909,
    800 : #464f07,
    900 : #343d03,
    A100 : #e6ff73,
    A200 : #ddff40,
    A400 : #d4ff0d,
    A700 : #c7f200,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);