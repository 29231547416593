@import '~@angular/material/theming';
@import './colours.scss';

// hack to allow for mat labels to wrap
// as per comment here
// https://stackoverflow.com/a/63260519/13882291
.mat-checkbox-layout {
    white-space: normal !important;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$lpa2-app-primary: mat-palette($lpa2-blue, 300);
$lpa2-app-accent:  mat-palette($lpa2-blue, 50);
$lpa2-app-warn:    mat-palette($lpa2-green);

// Create the theme object (a Sass map containing all of the palettes).
$lpa2-app-theme: mat-light-theme($lpa2-app-primary, $lpa2-app-accent, $lpa2-app-warn);

// create font families
@font-face {
	font-family: 'Reckless';
	src: url('assets/fonts/Reckless-Regular.woff');
}

@font-face {
	font-family: 'Reckless-Light';
	src: url('assets/fonts/Reckless-Regular.woff');
}

$body-button: mat-typography-level(12px, 18px, 400,'Roboto,"Helvetica Neue",sans-serif');

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-fonts: mat-typography-config(
  $font-family: 'Roboto,"Helvetica Neue",sans-serif',
  $headline: mat-typography-level(20px, 30px, 400,'Roboto,"Helvetica Neue",sans-serif'),
  $button: $body-button,
  $body-1: $body-button
);

// Override the typography in the core CSS.
@include mat-core($custom-fonts);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($lpa2-app-theme);